/**
 * 动画控制模块
 */

/**
 * 多个动画依次从头到尾循环播放
 * @constructor
 */
export default class LoopAnimations {

    animations = [];
    delay = 0;

    /**
     *
     * @param animations 要循环播放的动画
     * @param delay 最后一个动画播放完后重新开始的延时时间
     */
    constructor(animations, delay) {
        this.delay = delay;
        if (animations instanceof Array){
            this.concatAnimation(animations);
            this.animations.push(...animations);
        }
    }

    //连接动画
    concatAnimation(animations){
        const lastAnimationIndex = animations.length - 1;
        for (let i = 0; i < lastAnimationIndex; i++) {
            animations[i].finished.then(() => {
                animations[i + 1].play();
            });
        }
        this.registerLastAnimationFinished(animations);
    }

    //处理最后一个动画完成事件
    registerLastAnimationFinished(animations){
        const lastAnimationIndex = animations.length - 1;
        const lastAnimation = animations[lastAnimationIndex];

        lastAnimation.finished.then(()=>{
            setTimeout(()=>{
                for (let i = 0; i < animations.length; i++) {
                    animations[i].cancel();
                }

                this.concatAnimation(animations);

                animations[0].play();
            }, this.delay);
        });
    }

    run() {
        this.animations[0].play();
    }

}