import React, {useEffect} from "react";

// import './index.css';

import {} from './index.module.css';
import LoopAnimations from "../../../lib/loop-animations";


export default function Index() {


    useEffect(() => {
        const div1 = document.getElementById('div1');
        const div2 = document.getElementById('div2');
        const div3 = document.getElementById('div3');
        // const div4 = document.getElementById('div4');

        const keyframes = [
            {marginLeft: 0},
            {marginLeft: '-100%'}
        ];

        const timing = {
            duration: 2000,
            iterations: 1,
            fill: 'forwards'
        };

        var rabbitDownKeyframes1 = new KeyframeEffect(div1, keyframes, timing);
        var rabbitDownAnimation1 = new Animation(rabbitDownKeyframes1, document.timeline);

        var rabbitDownKeyframes2 = new KeyframeEffect(div2, keyframes, timing);
        var rabbitDownAnimation2 = new Animation(rabbitDownKeyframes2, document.timeline);

        var rabbitDownKeyframes3 = new KeyframeEffect(div3, keyframes, timing);
        var rabbitDownAnimation3 = new Animation(rabbitDownKeyframes3, document.timeline);


        document.getElementById('parent').addEventListener('mousedown', () => {
            new LoopAnimations([rabbitDownAnimation1,rabbitDownAnimation2,rabbitDownAnimation3]).run();
        });


    }, [])

    return (
        <div id={'parent'}
             style={{backgroundColor: 'white', display: 'flex', width: '12em', height: '10em', overflow: 'hidden'}}>
            <div id={'div1'} style={{backgroundColor: 'red', width: '100%', height: '100%', flex: 'none'}}>
                <span>1</span></div>
            <div id={'div2'} style={{backgroundColor: 'green', width: '100%', height: '100%', flex: 'none'}}>
                <span>2</span></div>
            <div id={'div3'} style={{backgroundColor: 'blue', width: '100%', height: '100%', flex: 'none'}}>
                <span>3</span></div>
            <div id={'div4'} style={{backgroundColor: 'orange', width: '100%', height: '100%', flex: 'none'}}>
                <span>4</span></div>
        </div>
    );
}